import React from 'react'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Accordion from '../components/Accordion'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import TopicTemplate from '../components/TopicTemplate'

function Topic({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide, Card, CardContainer, ShowMore }
  const { topic } = pageContext

  return (
    <div className="Topic">
      <TopicTemplate topicTitle={topic.frontmatter.topicTitle} sectionTitle={topic.frontmatter.sectionTitle} image={topic.frontmatter.image} path={topic.slug}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {topic.body}
          </MDXRenderer>
        </MDXProvider>
      </TopicTemplate>
    </div>
  )
}

export default Topic;