import React from 'react'
import Ankle from './Ankle'
import Footer from './Footer'
import SectionHeader from './SectionHeader'
import BreadCrumbs from './BreadCrumbs'
import './TopicTemplate.scss'

const TopicTemplate = ({topicTitle, sectionTitle, image, path, children}) => {
  return (
    <div className='TopicTemplate'>
      <SectionHeader >
      </SectionHeader>
      <BreadCrumbs topicTitle={topicTitle} sectionTitle={sectionTitle} path={path} />
      <div className="topic-header">
        <img src={`/assets/aloevera.jpeg`} alt={topicTitle}/>
        <div className="headers">
          <h4>{sectionTitle}</h4>
          <h1>{topicTitle}</h1>
        </div>
      </div>
      <div className="crib">
        {children}
      </div>
      <Ankle>
      </Ankle>
      <Footer>
      </Footer>
    </div>
  )
}

export default TopicTemplate