import React from 'react'
import { Link } from 'gatsby'
import MDXSplitter from './MDXSplitter'
import './Card.scss'


const Card = ({link, parentTitle, cardTitle, image, children}) => {
  const style = {
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(/assets/${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  };

  return (
    <div id='Card'>
      <Link to={link}>
        <div className="color-block" style={style}>
          <p>{parentTitle}</p>
        </div>
        <div className="content">
          <h3>{cardTitle}</h3>
          <MDXSplitter>
            {children}
          </MDXSplitter>
        </div>
      </Link>
    </div>
  )
}

export default Card