import React, {useState} from 'react'
import MDXSplitter from './MDXSplitter'
import './Accordion.scss'

const Accordion = ({title, children, forceOpen}) => {
  const [contentOpen, setContentOpen] = useState(false);
  return (
    <div className='accordion-container'>
      <div className="button">
        <button className={contentOpen ? 'open': ''} onClick={() => setContentOpen(!contentOpen)}>
          <div className='inner-button'>
            <span className="label">{title} </span>
            <div className={contentOpen ? 'down-caret': 'up-caret'}/>
          </div>
        </button>
      </div>
      {(contentOpen || forceOpen) &&
        <div className="content">
          <MDXSplitter>{children}</MDXSplitter>
        </div>
      }
    </div>
  )

}

export default Accordion